<script setup lang="ts">
import { Link, usePage } from '@inertiajs/vue3'
import { useI18n } from 'vue-i18n'
// import ImageA from '@images/categories/hero/jewellery.jpg?w=100;300&format=webp;jpg&as=picture&imagetools'
// import Image from '@/Components/Image.vue'

const page = usePage()
const { t } = useI18n()

const jewelleryCategory = page.props.layout.categories.find((category) => category.code === 'jewellery')
const ringsCategory = jewelleryCategory?.descendants.find((category) => category.code === 'rings')
const earringsCategory = jewelleryCategory?.descendants.find((category) => category.code === 'earrings')
const braceletsCategory = jewelleryCategory?.descendants.find((category) => category.code === 'bracelets')
const pendantsCategory = jewelleryCategory?.descendants.find((category) => category.code === 'pendants')
</script>

<template>
    <!-- <Image :picture="ImageA" :alt="ringsCategory.name" class="inset-0 object-cover object-center w-full h-full transition-transform duration-200 ease-in-out group-hover:scale-105" /> -->
    <section class="bg-gradient-to-b from-gray-50 to-white">
        <div class="px-4 py-16 mx-auto max-w-7xl sm:py-24 sm:px-6 lg:px-8">
            <div class="mt-1 sm:flex sm:items-baseline sm:justify-between">
                <h1 class="text-2xl font-bold tracking-tight text-gray-900">{{ t('pages.home.featured-categories.title') }}</h1>

                <Link v-if="jewelleryCategory" :href="route('shop.category', jewelleryCategory.slug_path)" class="hidden text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block">
                {{ t('pages.home.featured-categories.all-categories') }}
                <span aria-hidden="true"> &rarr;</span>
                </Link>
            </div>

            <div class="grid grid-cols-1 mt-6 gap-y-6 sm:grid-cols-3 sm:grid-rows-2 sm:gap-x-6 lg:gap-8">
                <div class="relative z-10 overflow-hidden group aspect-w-2 aspect-h-1 rounded-2xl sm:aspect-w-1">
                    <template v-if="ringsCategory">
                        <picture>
                            <source data-srcset="/images/sections/categories/rings.webp" type="image/webp">
                            <source data-srcset="/images/sections/categories/rings.jpg" type="image/jpeg">

                            <img data-src="/images/sections/categories/rings.jpg" :alt="ringsCategory.name" class="inset-0 object-cover object-center w-full h-full transition-transform duration-200 ease-in-out lazyload group-hover:scale-105">
                        </picture>
                        <div aria-hidden="true" class="opacity-50 bg-gradient-to-b from-transparent to-black"></div>

                        <div class="absolute inset-0 flex items-end p-6">
                            <div>
                                <h3 class="font-semibold text-white">
                                    <Link :href="route('shop.category', ringsCategory.slug_path)">
                                    <span class="absolute inset-0"></span>
                                    {{ ringsCategory.name }}
                                    </Link>
                                </h3>

                                <p aria-hidden="true" class="mt-1 text-sm text-white">{{ t('pages.home.featured-categories.shop-now') }}</p>
                            </div>
                        </div>
                    </template>
                </div>

                <div class="relative z-10 col-span-2 overflow-hidden group aspect-w-2 aspect-h-1 rounded-2xl">
                    <template v-if="earringsCategory">
                        <picture>
                            <source data-srcset="/images/sections/categories/earrings.webp" type="image/webp">
                            <source data-srcset="/images/sections/categories/earrings.jpg" type="image/jpeg">

                            <img data-src="/images/sections/categories/earrings.jpg" :alt="earringsCategory.name" class="inset-0 object-cover object-center w-full h-full transition-transform duration-200 ease-in-out lazyload group-hover:scale-105">
                        </picture>
                        <div aria-hidden="true" class="opacity-50 bg-gradient-to-b from-transparent to-black sm:absolute sm:inset-0"></div>

                        <div class="absolute inset-0 flex items-end p-6">
                            <div>
                                <h3 class="font-semibold text-white">
                                    <Link :href="route('shop.category', earringsCategory.slug_path)">
                                    <span class="absolute inset-0"></span>
                                    {{ earringsCategory.name }}
                                    </Link>
                                </h3>

                                <p aria-hidden="true" class="mt-1 text-sm text-white">{{ t('pages.home.featured-categories.shop-now') }}</p>
                            </div>
                        </div>
                    </template>
                </div>

                <div class="z-10 col-span-2 overflow-hidden elative group aspect-w-2 aspect-h-1 rounded-2xl">
                    <template v-if="braceletsCategory">
                        <picture>
                            <source data-srcset="/images/sections/categories/bracelets.webp" type="image/webp">
                            <source data-srcset="/images/sections/categories/bracelets.jpg" type="image/jpeg">

                            <img data-src="/images/sections/categories/bracelets.jpg" :alt="braceletsCategory.name" class="inset-0 object-cover object-center w-full h-full transition-transform duration-200 ease-in-out lazyload group-hover:scale-105">
                        </picture>
                        <div aria-hidden="true" class="opacity-50 bg-gradient-to-b from-transparent to-black sm:absolute sm:inset-0"></div>

                        <div class="absolute inset-0 flex items-end p-6">
                            <div>
                                <h3 class="font-semibold text-white">
                                    <Link :href="route('shop.category', braceletsCategory.slug_path)">
                                    <span class="absolute inset-0"></span>
                                    {{ braceletsCategory.name }}
                                    </Link>
                                </h3>

                                <p aria-hidden="true" class="mt-1 text-sm text-white">{{ t('pages.home.featured-categories.shop-now') }}</p>
                            </div>
                        </div>
                    </template>
                </div>

                <div class="relative z-10 overflow-hidden group aspect-w-2 aspect-h-1 rounded-2xl sm:aspect-w-1">
                    <template v-if="pendantsCategory">
                        <picture>
                            <source data-srcset="/images/sections/categories/pendants.webp" type="image/webp">
                            <source data-srcset="/images/sections/categories/pendants.jpg" type="image/jpeg">

                            <img data-src="/images/sections/categories/pendants.jpg" :alt="pendantsCategory.name" class="inset-0 object-cover object-right w-full h-full transition-transform duration-200 ease-in-out lazyload group-hover:scale-105">
                        </picture>
                        <div aria-hidden="true" class="opacity-50 bg-gradient-to-b from-transparent to-black"></div>

                        <div class="absolute inset-0 flex items-end p-6">
                            <div>
                                <h3 class="font-semibold text-white">
                                    <Link :href="route('shop.category', pendantsCategory.slug_path)">
                                    <span class="absolute inset-0"></span>
                                    {{ pendantsCategory.name }}
                                    </Link>
                                </h3>

                                <p aria-hidden="true" class="mt-1 text-sm text-white">{{ t('pages.home.featured-categories.shop-now') }}</p>
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <div v-if="jewelleryCategory" class="mt-6 sm:hidden">
                <Link :href="route('shop.category', jewelleryCategory.slug_path)" class="block text-sm font-semibold text-indigo-600 hover:text-indigo-500">
                {{ t('pages.home.featured-categories.all-categories') }}
                <span aria-hidden="true"> &rarr;</span>
                </Link>
            </div>
        </div>
    </section>
</template>
